import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardTitle, InputGroupAddon } from 'reactstrap';
import { useFormikContext } from 'formik';
import { Field, Input } from '@availity/form';
import { SelectField } from '@availity/select';
// import { FormikSwitch } from '@/components';
import { SwitchButton } from '@/components';

const creatableListFilters = [
  'postalCode',
  'vendorSubID',
  'vendorPubID',
  'vendorLandingPage',
  'domain',
  'vendorSource',
  'activeProspectOperatingSystem',
  'activeProspectIp',
  'activeProspectDevice',
  'activeProspectPageUrl',
  'activeProspectParentPageURL',
  'activeProspectPageID',
  'activeProspectFormInputMethod',
  'activeProspectFramed',
  'activeProspectCountryCode',
  'activeProspectBrowser',
  'activeProspectTimeZone',
  'activeProspectDomain',
];

const overideDuplicateChecksOptions = [
  { value: 'vendorLandingPage', label: 'Landing Page' },
  { value: 'domain', label: 'Domain' },
];

const OtherCard: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<{
    isCampaignDestination: boolean;
    verifyDestination: boolean;
    skipActiveDemandSubmit: boolean;
    allowMLOverride: boolean;
    overideDuplicateChecks: boolean;
    isUpdateEligible: boolean;
    allowAPOnPing: boolean;
    allowSlices: boolean;
    allowMLearning: boolean;
    smartPriceAcceptence: boolean;
    dupPriceThreashold: number;
    dupPriceThreasholdLookBack: number;
    allowSplits: boolean;
    useEventsMLModel: boolean;
    allowMLPriceOverride: boolean;
    minAcceptenceBid: number;
    mlTargetMaxPrice: number;
    mlTargetMinPrice: number;
    mlTargetConversionRate: number;
    mlAggressionConversionLevel: number;
    mlAggressionPriceLevel: number;
  }>();
  const { setFieldTouched } = useFormikContext();
  const [tempFlag, setTempFlag] = useState(values.isCampaignDestination);

  // useEffect() Need to know when form is done saving to change switch message or need to do someting different

  return (
    <>
      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Make Campaign Destination
        </CardTitle>
        <Row>
          <Col xs={2} className="mt-3">
            {/* <FormikSwitch
            name="isCampaignDestination"
            label="Turn On"
            helpMessage="Show this lead source as a destination when creating a marketplace campaign"
          /> */}
            <SwitchButton
              disabled={tempFlag}
              width={120}
              checked={values.isCampaignDestination}
              onlabel={!tempFlag ? 'Pending' : 'Active'}
              offlabel="Create"
              onstyle="success"
              offstyle="primary"
              onChange={() => {
                setFieldValue(
                  'isCampaignDestination',
                  (values.isCampaignDestination = !values.isCampaignDestination)
                );
              }}
            />
          </Col>
        </Row>
      </Card>

      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Verify Destination
        </CardTitle>
        <div id="live-transfers-switch-button">
          <SwitchButton
            width={100}
            checked={!!values.verifyDestination}
            onlabel="Enabled"
            offlabel="Disabled"
            onstyle="success"
            offstyle="danger"
            onChange={() =>
              setFieldValue('verifyDestination', !values.verifyDestination)
            }
          />
        </div>
      </Card>

      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Skip Active Demand on Submit Requests
        </CardTitle>
        <Row>
          <Col xs={2} className="mt-3">
            <SwitchButton
              width={100}
              checked={!!values.skipActiveDemandSubmit}
              onlabel="Enabled"
              offlabel="Disabled"
              onstyle="success"
              offstyle="danger"
              onChange={() =>
                setFieldValue(
                  'skipActiveDemandSubmit',
                  !values.skipActiveDemandSubmit
                )
              }
            />
          </Col>
        </Row>
      </Card>

      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Active Demand Settings
        </CardTitle>
        <Row>
          <Col xs={7} className="mt-3">
            <Field
              id="lead-max-demand-unsold-accepted"
              name="maxDemandUnsoldAccepted"
              label="Unsold with Active Demand On"
              type="number"
              onBlur={({ target: { value } }) => {
                setFieldValue('maxDemandUnsoldAccepted', parseInt(value, 10));
                setFieldTouched('maxDemandUnsoldAccepted', true, true);
              }}
              helpMessage="Set the max number of leads accepted and unsold until activating active demand"
            />
          </Col>
        </Row>
      </Card>

      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Update Lead Data Eligible
        </CardTitle>
        <Row>
          <Col xs={6} className="mt-3 ml-3">
            <Row className="mb-1">
              <SwitchButton
                width={120}
                checked={values.isUpdateEligible}
                onlabel="On"
                offlabel="Off"
                onstyle="success"
                offstyle="primary"
                onChange={() => {
                  setFieldValue(
                    'isUpdateEligible',
                    (values.isUpdateEligible = !values.isUpdateEligible)
                  );
                }}
              />
            </Row>
            <Row>
              <Field
                id="delay-seconds-for-update"
                name="delaySecondsForUpdate"
                label="Delay Seconds For Update"
                type="number"
                onBlur={({ target: { value } }) => {
                  setFieldValue('delaySecondsForUpdate', parseInt(value, 10));
                }}
                helpMessage="Set the delay in seconds to wait for more information on the lead"
              />
            </Row>
          </Col>
        </Row>
      </Card>

      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Override Duplicate Rejects
        </CardTitle>
        <Row>
          <Col xs={2} className="mt-3">
            <SwitchButton
              width={100}
              checked={!!values.overideDuplicateChecks}
              onlabel="Enabled"
              offlabel="Disabled"
              onstyle="success"
              offstyle="danger"
              onChange={() =>
                setFieldValue(
                  'overideDuplicateChecks',
                  !values.overideDuplicateChecks
                )
              }
            />
          </Col>
        </Row>
      </Card>
      <Card body className="mt-3">
        <Row>
          <Col xs={2}>
            <CardTitle tag="h4" className="lead text-uppercase">
              Allow ML
            </CardTitle>
            <SwitchButton
              width={100}
              checked={!!values.allowMLearning}
              onlabel="Enabled"
              offlabel="Disabled"
              onstyle="success"
              offstyle="danger"
              onChange={() =>
                setFieldValue('allowMLearning', !values.allowMLearning)
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={4}>
            <CardTitle tag="h4" className="lead text-uppercase">
              Use Events ML Model
            </CardTitle>
            <div id="switch-button-1">
              <SwitchButton
                width={100}
                checked={!!values.useEventsMLModel}
                onlabel="Enabled"
                offlabel="Disabled"
                onstyle="success"
                offstyle="danger"
                onChange={() =>
                  setFieldValue('useEventsMLModel', !values.useEventsMLModel)
                }
              />
            </div>
          </Col>
          <Col xs={4}>
            <CardTitle tag="h4" className="lead text-uppercase">
              ML Price Override
            </CardTitle>
            <div id="switch-button-2">
              <SwitchButton
                width={100}
                checked={!!values.allowMLPriceOverride}
                onlabel="Enabled"
                offlabel="Disabled"
                onstyle="success"
                offstyle="danger"
                onChange={() =>
                  setFieldValue(
                    'allowMLPriceOverride',
                    !values.allowMLPriceOverride
                  )
                }
              />
            </div>
          </Col>
          <Row className="mt-3 ml-1">
            <Col xs={3}>
              <Field
                id="ml-target-min-price"
                name="mlTargetMinPrice"
                label="Target Price Min"
                type="number"
                min={0}
                step="0.01"
                onBlur={({ target: { value } }) => {
                  setFieldValue('mlTargetMinPrice', parseFloat(value, 0));
                }}
                helpMessage="Set the target min price"
              />
            </Col>
            <Col xs={3}>
              <Field
                id="ml-target-max-price"
                name="mlTargetMaxPrice"
                label="Target Price Max"
                type="number"
                min={0}
                step="0.01"
                onBlur={({ target: { value } }) => {
                  setFieldValue('mlTargetMaxPrice', parseFloat(value, 0));
                }}
                helpMessage="Set the target max price"
              />
            </Col>
            <Col xs={3}>
              <Field
                id="ml-target-max-price"
                name="minAcceptenceBid"
                label="Min Accepted Bid"
                type="number"
                min={0}
                step="0.01"
                onBlur={({ target: { value } }) => {
                  setFieldValue('minAcceptenceBid', parseFloat(value, 0));
                }}
                helpMessage="Set the min accepted price"
              />
            </Col>
            <Col xs={3}>
              <Field
                id="ml-target-conversion-rate"
                name="mlTargetConversionRate"
                label="Target Conversion Rate"
                type="number"
                min={0}
                step="any"
                onBlur={({ target: { value } }) => {
                  setFieldValue('mlTargetConversionRate', parseFloat(value));
                }}
                helpMessage="Set the target conversion rate on the lead"
              />
            </Col>
          </Row>
          <Row className="ml-1">
            <Col xs={6}>
              <Field
                id="ml-agg-price"
                name="mlAggressionPriceLevel"
                label="Price Aggression"
                type="number"
                min={0}
                max={1}
                step="0"
                onBlur={({ target: { value } }) => {
                  setFieldValue('mlAggressionPriceLevel', parseInt(value, 0));
                }}
                helpMessage="Set aggression price level"
              />
            </Col>
            <Col xs={6}>
              <Field
                id="ml-agg-conv"
                name="mlAggressionConversionLevel"
                label="Conversion Aggression"
                type="number"
                min={0}
                max={1}
                step="0"
                onBlur={({ target: { value } }) => {
                  setFieldValue(
                    'mlAggressionConversionLevel',
                    parseInt(value, 0)
                  );
                }}
                helpMessage="Set aggression conversation level"
              />
            </Col>
          </Row>
        </Row>
      </Card>
      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          ML Override Rejects
        </CardTitle>
        <Row>
          <Col xs={2} className="mt-3">
            <SwitchButton
              width={100}
              checked={!!values.allowMLOverride}
              onlabel="Enabled"
              offlabel="Disabled"
              onstyle="success"
              offstyle="danger"
              onChange={() =>
                setFieldValue('allowMLOverride', !values.allowMLOverride)
              }
            />
          </Col>
        </Row>
      </Card>
      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Allow AP On Pings
        </CardTitle>
        <Row>
          <Col xs={2} className="mt-3">
            <SwitchButton
              width={100}
              checked={!!values.allowAPOnPing}
              onlabel="Enabled"
              offlabel="Disabled"
              onstyle="success"
              offstyle="danger"
              onChange={() =>
                setFieldValue('allowAPOnPing', !values.allowAPOnPing)
              }
            />
          </Col>
        </Row>
      </Card>
      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Smart Price Acceptence
        </CardTitle>
        <Row>
          <Col xs={2} className="mt-3">
            <SwitchButton
              width={100}
              checked={!!values.smartPriceAcceptence}
              onlabel="Enabled"
              offlabel="Disabled"
              onstyle="success"
              offstyle="danger"
              onChange={() =>
                setFieldValue(
                  'smartPriceAcceptence',
                  !values.smartPriceAcceptence
                )
              }
            />
          </Col>
        </Row>
        <Row className="mt-3 ml-1">
          <p>
            When using smart acceptence, only phone duplicate checks are
            applied. Any other duplicate check will be ignored on this layer
          </p>
        </Row>
      </Card>

      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Corporate Duplicate Check Override
        </CardTitle>
        <Row className="mt-3 ml-1">
          <Col xs={3}>
            <Field
              id="dup-price-threashold"
              name="dupPriceThreashold"
              label="Duplicate Override Price"
              type="number"
              min={0}
              step="0.01"
              onBlur={({ target: { value } }) => {
                setFieldValue('dupPriceThreashold', parseFloat(value, 0));
              }}
              helpMessage="Set the max price to allow a corp dup check override."
            />
          </Col>
          <Col xs={3}>
            <Field
              id="dup-price-threashol-lookback"
              name="dupPriceThreasholdLookBack"
              label="Duplicate Override Look Back"
              type="number"
              min={0}
              step="1"
              onBlur={({ target: { value } }) => {
                setFieldValue(
                  'dupPriceThreasholdLookBack',
                  parseFloat(value, 0)
                );
              }}
              helpMessage="Set look back days for the company check on a corp dup check override"
            />
          </Col>
        </Row>
      </Card>

      <Card body className="mt-3 mb-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Corp or GTB Dup Check Override
        </CardTitle>
        <Row>
          <Col xs={2} className="ml-4">
            <SelectField
              id="attribute-static-list-filter-values"
              name="overrideBlockedAttributeFields"
              options={overideDuplicateChecksOptions}
              label="Attribute"
              onChange={(val: any) => {
                setFieldValue('overrideBlockedAttributeFields', val);
              }}
              // labelKey="label"
              // valueKey="value"
              raw
            />
          </Col>
          <Col xs={5} className="ml-4">
            <SelectField
              id="attribute_val-static-list-filter-values"
              name="overrideBlockedValues"
              options={[]}
              label="Attribute Values"
              // isDisabled={level === 'company'}
              onChange={(val: any) => {
                setFieldValue('overrideBlockedValues', val || []);
              }}
              isMulti
              creatable
              raw
            />
          </Col>
        </Row>
      </Card>
      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Allow Slices
        </CardTitle>
        <Row>
          <Col xs={2} className="mt-3">
            <SwitchButton
              width={100}
              checked={!!values.allowSlices}
              onlabel="Enabled"
              offlabel="Disabled"
              onstyle="success"
              offstyle="danger"
              onChange={() => setFieldValue('allowSlices', !values.allowSlices)}
            />
          </Col>
        </Row>
      </Card>
      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Allow Splits
        </CardTitle>
        <Row>
          <Col xs={2} className="mt-3">
            <SwitchButton
              width={100}
              checked={!!values.allowSplits}
              onlabel="Enabled"
              offlabel="Disabled"
              onstyle="success"
              offstyle="danger"
              onChange={() => setFieldValue('allowSplits', !values.allowSplits)}
            />
          </Col>
        </Row>
      </Card>
      <Card body className="mt-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Number of Times to Split a Lead
        </CardTitle>
        <Row>
          <Col xs={7} className="mt-3">
            <Field
              id="split-amount"
              name="splitCount"
              label="Split Amount"
              type="number"
              onBlur={({ target: { value } }) => {
                setFieldValue('splitCount', parseInt(value, 10));
                setFieldTouched('splitCount', true, true);
              }}
              helpMessage="Set the number of times is split into shared leads"
            />
          </Col>
        </Row>
      </Card>
      <Card body className="mt-3 mb-3">
        <CardTitle tag="h4" className="lead text-uppercase">
          Cross Network Layers
        </CardTitle>
        <Col xs={5} className="ml-4">
          <SelectField
            id="attribute_val-crossnetwork-layer-values"
            name="crossNetworkLayers"
            options={[]}
            label="Cross Network Layers"
            // isDisabled={level === 'company'}
            onChange={(val: any) => {
              setFieldValue('crossNetworkLayers', val || []);
            }}
            isMulti
            creatable
            raw
          />
        </Col>
      </Card>
    </>
  );
};

export default OtherCard;
